import React from "react";

import svgLogo from "./logo.svg";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
function Logo() {
  return (
    <div>
      <img src={svgLogo} width="75px" height="75px" />
    </div>
  );
}

export default Logo;
